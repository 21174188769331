import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import omne_client from '../../../images/clients/omne_client.png'
import joyride_client from '../../../images/clients/joyride_client.png'
import singlife_client from '../../../images/clients/singlife_client.png'
import landers_client from '../../../images/clients/landers_client.png'
import mycrowd_client from '../../../images/clients/my_crowd_connect.webp'
import myvista_client from '../../../images/clients/myvista_client.webp'
import propertyVista from '../../../images/clients/Inspector_client.png'
import shoretrade from '../../../images/clients/shoretrade_client.png'

const partners = [
    {
        pImg: omne_client,
        pUrl: 'https://play.google.com/store/apps/details?id=global.fwd.omne&hl=en_US'
    },
    {
        pImg: singlife_client,
        pUrl: 'https://play.google.com/store/apps/details?id=ph.com.singlife.app&hl=en_US'
    },
    {
        pImg: landers_client,
        pUrl: 'https://play.google.com/store/apps/details?id=com.snapmart.landers&hl=en_US'
    },
    {
        pImg: joyride_client,
        pUrl: 'https://play.google.com/store/apps/details?id=com.joyride.rider&hl=en_US'
    },

    {
        pImg: myvista_client,
        pUrl: 'https://play.google.com/store/apps/details?id=com.propertyvista.myvista&hl=en_US'
    },
    {
        pImg: mycrowd_client,
        pUrl: 'https://play.google.com/store/apps/details?id=com.mycrowdmobile&hl=en_US'
    },
    {
        pImg: propertyVista,
        pUrl: 'https://play.google.com/store/apps/details?id=com.pvprototype&hl=en_US'
    },
    {
        pImg: shoretrade,
        pUrl: 'https://play.google.com/store/apps/details?id=com.shoretradeapp.buyer&hl=en_US'
    },
]

var settings = {
    dots: false,
    infinite: true,
    speed: 3000,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    arrows: false,

    responsive: [
        {
            breakpoint: 1025,
            settings: {
                slidesToShow: 7,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 350,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};



const FeaturePartners = (props) => {

    return (
        <div className="feature_partners_section">
            <div className="container position-relative">
                <div className="title_text text-white">
                    Our Featured Client's
                </div>
                <div className="client_logo_carousel">
                    <Slider {...settings}>
                        {partners.map((partner, pitem) => (
                            <div className="client_logo_item" key={pitem}>
                                <a href={partner.pUrl} target="_blank" rel="noopener noreferrer">
                                    <img src={partner.pImg} alt="QuadGen - Client Logo" />
                                </a>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default FeaturePartners;