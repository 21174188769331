import { useEffect } from "react";

function SaasFirstChat() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://chat.saasfirst.com/assets/js/saasfirst.js";
    script.async = true;
    document.body.appendChild(script);

    window.saasFirstSettings = {
      workspace_id: "iaz7yyudj",
    };

    return () => {
      document.body.removeChild(script); // Clean up the script when the component unmounts
    };
  }, []);

  return null; // This component only handles loading the script
}

export default SaasFirstChat;
