import React, { useState } from "react";
import Bg from "../../../images/shapes/shape_title_under_line.svg";
import shape1 from "../../../images/shapes/shape_line_2.svg";
import shape2 from "../../../images/shapes/shape_line_3.svg";
import shape3 from "../../../images/shapes/shape_line_4.svg";
import shape4 from "../../../images/shapes/shape_space_3.svg";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import TechnologySection from "../TechnologySection/TechnologySection";
// import Testimonial from '../Testimonial/Testimonial';

const FaqSection = (props) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <section
      id="technologies_section"
      className="process_technology_review_section bg-light section_decoration"
    >
      <div className="container">
        <div className="row align-items-center justify-content-lg-between">
          <div className="col-lg-6">
            <div className="heading_block">
              <div
                className="heading_focus_text has_underline d-inline-flex"
                style={{ backgroundImage: `url(${Bg})` }}
              >
                Working Process
              </div>
              <h2 className="heading_text mb-0">
                Our <mark>Approach</mark>
              </h2>
            </div>
            <Accordion
              open={open}
              toggle={toggle}
              className="accordion"
              id="service_process_faq"
            >
              <AccordionItem className="accordion-item">
                <AccordionHeader targetId="1">
                  01. Discovery Phase
                </AccordionHeader>
                <AccordionBody accordionId="1" className="acc_body">
                  <p className="m-0">
                    The Discovery Phase in development is a crucial part of the
                    project lifecycle where the focus is on understanding and
                    defining the project’s requirements and objectives. This
                    phase is typically the first step before the actual design
                    and development work begins. Here’s a breakdown of what
                    happens during the Discovery Phase:
                    <p>
                      1. <mark>Understanding Requirements</mark>: The primary
                      goal is to gather and clarify what the stakeholders need.
                      This involves: - **Stakeholder Interviews**: Talking to
                      the people who have a vested interest in the project to
                      understand their needs and expectations. - **Surveys and
                      Questionnaires**: Collecting information from a larger
                      group to identify common needs and preferences.
                    </p>
                    <p>
                      2. <mark>Defining Objectives</mark>: Establishing clear,
                      actionable goals for the project. This might include: -
                      <b>Project Scope</b>: Determining what will and will not
                      be included in the project. - <b>Success Criteria</b>:
                      Identifying what constitutes success for the project.
                    </p>
                    <p>
                      3. <mark>Analyzing Current State</mark>: Understanding the
                      current situation or system, if applicable. This might
                      involve: - <b>Reviewing Existing Documentation</b>:
                      Looking at any existing reports, systems, or processes. -
                      <b>Conducting Workshops</b>: Facilitating sessions to
                      explore current challenges and opportunities.
                    </p>
                    <p>
                      4. <mark>Identifying Constraints</mark>: Recognizing any
                      limitations or restrictions that could impact the project.
                      This could include: - <b>Budget Constraints</b>:
                      Understanding financial limitations. -{" "}
                      <b>Technical Constraints</b>: Identifying any technical
                      limitations or requirements.
                    </p>
                    <p>
                      5. <mark>Creating User Personas</mark>: Developing
                      detailed profiles of the end-users to guide design and
                      development decisions.
                    </p>
                    <p>
                      6. <mark>Mapping User Journeys</mark>: Outlining the steps
                      users will take to achieve their goals with the system or
                      product.
                    </p>
                    <p>
                      7. <mark>Defining Key Features and Functionality</mark>:
                      Outlining the main features and functionalities that need
                      to be included.
                    </p>
                    <p>
                      8.<mark>Feasibility Analysis</mark>: Assessing whether the
                      project goals can be achieved with the available
                      resources, time, and technology.
                    </p>
                    <p>
                      9. <mark>Creating a Project Plan</mark>: Developing a
                      roadmap that outlines the phases, timelines, and
                      milestones for the project.
                    </p>
                    <p>
                      10. <mark>Risk Assessment</mark>: Identifying potential
                      risks and creating mitigation strategies.
                    </p>
                    The output of the Discovery Phase is often a detailed
                    document or set of documents that includes the project’s
                    requirements, objectives, and constraints. This sets the
                    foundation for the subsequent phases of design, development,
                    and implementation.
                  </p>
                </AccordionBody>
              </AccordionItem>
              <AccordionItem className="accordion-item">
                <AccordionHeader targetId="2">
                  02. Design and Development
                </AccordionHeader>
                <AccordionBody accordionId="2" className="acc_body">
                  <p className="m-0">
                    The Design and Development phases are critical stages in the
                    project lifecycle where the ideas and requirements gathered
                    during the Discovery Phase are turned into a tangible
                    product or solution. Here’s a breakdown of what happens
                    during each phase: ### Design Phase
                    <p>
                      1. <mark>Conceptual Design</mark>:
                      <p>
                        - <b>High-Level Architecture</b>: Creating a broad
                        design framework that outlines how different components
                        of the system will interact.
                      </p>
                      <p>
                        - <b>Wireframes and Mockups</b>: Developing visual
                        representations of the user interface to illustrate
                        layout and functionality.
                      </p>
                    </p>
                    <p>
                      2. <mark>Detailed Design</mark>:
                      <p>
                        - <b>UI/UX Design</b>: Designing detailed user
                        interfaces and user experiences. This includes creating
                        detailed wireframes, prototypes, and user flows.
                      </p>
                      <p>
                        - <b>System Design</b>: Defining the architecture of the
                        system, including data models, algorithms, and
                        integration points.
                      </p>
                      <p>
                        - <b>Design Specifications**</b>: Documenting detailed
                        design requirements, including technical specifications
                        and user interface guidelines.
                      </p>
                    </p>
                    <p>
                      3. <mark>Prototyping</mark>:
                      <p>
                        - <b>Building Prototype</b>: Developing working models
                        of the system or specific features to test design
                        concepts and gather feedback.
                      </p>
                      <p>
                        - <b>User Testing</b>: Conducting usability tests with
                        end-users to refine and improve the design based on
                        feedback.
                      </p>
                    </p>
                    <p>
                      4. <mark>Review and Approval</mark>: - **Design Review**:
                      Presenting the design to stakeholders for feedback and
                      approval. - **Revisions**: Making necessary adjustments
                      based on feedback and re-submitting for approval if
                      needed.
                    </p>
                    ### Development Phase
                    <p>
                      1. <mark>Planning and Preparation</mark>: -{" "}
                      <b>Development Plan</b>: Creating a detailed plan that
                      outlines the development tasks, timelines, and resources
                      required.
                      <p>
                        - <b>Environment Setup</b> Setting up development
                        environments, including tools, platforms, and
                        technologies.
                      </p>
                    </p>
                    <p>
                      2. <mark>Coding and Implementation</mark>: -{" "}
                      <b>Front-End Development</b>: Coding the user interface
                      and client-side functionality.
                      <p>
                        - <b>Back-End Development</b>: Building the server-side
                        logic, databases, and APIs.
                      </p>
                      <p>
                        - <b>Integration</b>: Ensuring that different components
                        and systems work together seamlessly.
                      </p>
                    </p>
                    <p>
                      3. <mark>Testing and Quality Assurance</mark>:
                      <p>
                        - <b>Unit Testing</b>: Testing individual components or
                        modules to ensure they function correctly.
                      </p>
                      <p>
                        - <b>Integration Testing</b>: Testing the interactions
                        between different components to identify and fix any
                        issues.
                      </p>
                      <p>
                        - <b>System Testing</b>: Conducting end-to-end testing
                        to ensure the entire system works as intended.
                      </p>
                      <p>
                        - <b>User Acceptance Testing (UAT)</b>: Allowing
                        end-users to test the system in a real-world scenario to
                        verify that it meets their needs.
                      </p>
                    </p>
                    <p>
                      4. <mark>Bug Fixing and Refinement</mark>:{" "}
                      <p>
                        - <b>Issue Resolution</b>: Identifying and fixing bugs
                        or issues discovered during testing.
                      </p>
                      <p>
                        - <b>Performance Optimization</b>: Improving system
                        performance, scalability, and reliability.
                      </p>
                    </p>
                    <p>
                      5. <mark>Documentation</mark>:{" "}
                      <p>
                        - <b>Technical Documentation</b>: Creating documentation
                        for developers and technical support teams.
                      </p>
                      <p>
                        - <b>User Documentation</b>: Developing user manuals,
                        help guides, and other resources to assist end-users.
                      </p>
                    </p>
                    <p>
                      6. <mark>Deployment</mark>:{" "}
                      <p>
                        - <b>Deployment Planning</b>: Preparing for the launch
                        of the system, including deployment strategies and
                        rollback plans.
                      </p>
                      <p>
                        - <b>Release</b>: Deploying the system to the production
                        environment and making it available to end-users.
                      </p>
                    </p>
                    <p>
                      7. <mark>Post-Launch Support</mark>:{" "}
                      <p>
                        - <b>Monitoring</b>
                        Tracking the system’s performance and usage to ensure
                        stability.
                      </p>
                      <p>
                        - <b>Maintenance</b>: Providing ongoing support,
                        including bug fixes, updates, and enhancements based on
                        user feedback.
                      </p>
                    </p>
                    Throughout the Design and Development phases, communication
                    and collaboration among team members, stakeholders, and
                    users are essential to ensure that the final product meets
                    the project goals and user needs.
                  </p>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="accordion-item">
                <AccordionHeader targetId="3">
                  03. Testing and QA
                </AccordionHeader>
                <AccordionBody accordionId="3" className="acc_body">
                  <p className="m-0">
                    Testing and Quality Assurance (QA) are critical components
                    in the development lifecycle that ensure a product meets the
                    required standards of quality and performs as expected.
                    Here’s a detailed overview of these processes: ### Testing
                    Phase 1. <mark>Unit Testing</mark>:
                    <p>
                      {" "}
                      - <b>Definition</b>: Testing individual components or
                      functions in isolation to ensure they work correctly.{" "}
                    </p>
                    <p>
                      {" "}
                      - <b>Tools</b>: Frameworks like JUnit, NUnit, or pytest.{" "}
                    </p>
                    <p>
                      - <b>Purpose</b>: Detect bugs early in the development
                      process and ensure that each unit of code functions as
                      intended.{" "}
                    </p>
                    2. <mark>Integration Testing</mark>:
                    <p>
                      {" "}
                      - <b>Definition</b>: Testing the interactions between
                      integrated components or systems.{" "}
                    </p>
                    <p>
                      {" "}
                      - <b>Tools</b>: Tools like Postman (for APIs) or Selenium
                      (for UI).{" "}
                    </p>
                    <p>
                      - <b>Purpose</b>: Ensure that different modules or
                      services work together seamlessly.{" "}
                    </p>
                    3. <mark>System Testing</mark>:
                    <p>
                      {" "}
                      - <b>Definition</b>: Testing the complete and integrated
                      software to verify that it meets the specified
                      requirements.
                    </p>
                    <p>
                      {" "}
                      - <b>Tools</b>: Includes functional testing,
                      non-functional testing (performance, security), and
                      end-to-end testing.{" "}
                    </p>
                    <p>
                      - <b>Purpose</b>: Comprehensive testing tools like
                      TestComplete or QTP.
                    </p>
                    4. <mark>User Acceptance Testing (UAT)</mark>:
                    <p>
                      {" "}
                      - <b>Definition</b>: Testing conducted by end-users or
                      stakeholders to verify that the system meets their needs
                      and requirements.
                    </p>
                    <p>
                      {" "}
                      - <b>Tools</b>: Validate that the system functions in a
                      real-world scenario and fulfills user expectations.
                    </p>
                    <p>
                      - <b>Purpose</b>: Users perform tasks to check if the
                      system behaves as expected and meets their requirements.
                    </p>
                    5. <mark>Performance Testing</mark>:
                    <p>
                      {" "}
                      - <b>Definition</b>: Evaluating the system’s
                      responsiveness, stability, and scalability under varying
                      load conditions.
                    </p>
                    <p>
                      {" "}
                      - <b>Tools</b>: Includes Load Testing, Stress Testing, and
                      Scalability Testing.
                    </p>
                    <p>
                      - <b>Purpose</b>: Tools like JMeter, LoadRunner, or
                      Gatling.
                    </p>
                    6. <mark>Security Testing</mark>:
                    <p>
                      {" "}
                      - <b>Definition</b>: Identifying vulnerabilities, threats,
                      and risks in the software and ensuring that the system is
                      secure from attacks.
                    </p>
                    <p>
                      {" "}
                      - <b>Tools</b>: Includes Penetration Testing,
                      Vulnerability Scanning, and Risk Assessment.
                    </p>
                    <p>
                      - <b>Purpose</b>: Tools like OWASP ZAP, Nessus, or Burp
                      Suite.
                    </p>
                    7. <mark>Regression Testing</mark>:
                    <p>
                      {" "}
                      - <b>Definition</b>: Testing the software after changes or
                      enhancements to ensure that existing functionality has not
                      been adversely affected.
                    </p>
                    <p>
                      - <b>Purpose</b>: Ensure that new code changes do not
                      break or degrade existing features.
                    </p>
                    8. <mark>Usability Testing</mark>:
                    <p>
                      {" "}
                      - <b>Definition</b>: Evaluating the system’s user
                      interface and overall user experience.
                    </p>
                    <p>
                      - <b>Purpose</b>: Ensure that the software is intuitive
                      and easy to use for its intended audience.
                    </p>
                    9. <mark>Acceptance Testing</mark>:
                    <p>
                      {" "}
                      - <b>Definition</b>: A final testing phase to ensure the
                      software meets the agreed-upon requirements before it is
                      accepted for production.
                    </p>
                    <p>
                      - <b>Purpose</b>: Confirm that the product meets all the
                      specified acceptance criteria.
                    </p>
                    ### Quality Assurance (QA) 1. <mark>QA Planning</mark>:
                    <p>
                      -<b>Definition</b>: Establishing a QA strategy and plan
                      that outlines testing objectives, scope, resources, and
                      schedule.
                    </p>
                    <p>
                      - <b>Activities</b>: Define testing goals, select
                      appropriate tools, and set up a testing environment.
                    </p>
                    2. <mark>Test Case Development</mark>
                    <p>
                      - <b>Definition</b>: Creating detailed test cases that
                      outline the conditions, inputs, and expected results for
                      each test scenario.
                    </p>
                    <p>
                      - <b>Purpose</b>: Ensure that tests are comprehensive and
                      cover all aspects of the system.
                    </p>
                    3. <mark>Test Execution</mark>{" "}
                    <p>
                      - <b>Definition</b>: Running the test cases and
                      documenting the results.
                    </p>
                    <p>
                      - <b>Purpose</b>: Identify defects, measure performance,
                      and validate that the software meets the requirements.
                    </p>
                    4. <mark>Defect Management</mark>:
                    <p>
                      - <b>Definition</b>: Tracking, managing, and resolving
                      defects identified during testing.
                    </p>
                    <p>
                      - <b>Activities</b>: Logging defects, prioritizing them,
                      and working with the development team to resolve them.
                    </p>
                    5. <mark>Continuous Improvement</mark>:
                    <p>
                      - <b>Definition</b>: Regularly reviewing and improving QA
                      processes and practices.
                    </p>
                    <p>
                      - <b>Activities</b>: Analyze test results, gather
                      feedback, and implement improvements to enhance the
                      quality of future releases.
                    </p>
                    6. <mark>Automation</mark>:
                    <p>
                      -<b>Definition</b>: Implementing automated tests to
                      increase efficiency and coverage.
                      <p>
                        - <b>Tools</b>: Automation tools like Selenium, TestNG,
                        or Cypress.
                      </p>
                    </p>
                    <p>
                      - <b>Purpose</b>: Reduce manual testing efforts and
                      increase test coverage. Effective testing and QA practices
                      are essential for delivering high-quality software. They
                      help ensure that the product is reliable, secure, and
                      meets user expectations, ultimately leading to a better
                      user experience and reduced post-release issues.
                    </p>
                  </p>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="accordion-item">
                <AccordionHeader targetId="4">04. Deployment</AccordionHeader>
                <AccordionBody accordionId="4" className="acc_body">
                  <p className="m-0">
                    Deploy the application code to the production environment.
                    This might involve:
                    <p>
                      <mark>Application Servers</mark>: Deploying code to
                      application servers.
                    </p>
                    <p>
                      <mark>Database Changes</mark>: Applying database schema
                      updates or data migrations.
                    </p>
                    <p>
                      <mark>Configuration Updates</mark>: Updating configuration
                      files or settings as needed.
                    </p>
                    <p>
                      <mark>Release Management</mark>: Manage the release
                      process, including:
                      <ul>
                        <li>
                          <b>Version Control</b>: Ensure that the correct
                          version of the software is deployed.
                        </li>
                        <li>
                          <b>Release Notes</b>: Document the changes and
                          features included in the release.
                        </li>
                      </ul>
                    </p>
                    <mark>Monitoring</mark>: Monitor the deployment process to
                    ensure everything is functioning correctly. This includes:
                    <ul>
                      <li>
                        <b>Real-Time Monitoring</b>: Track system performance,
                        error logs, and user feedback.
                      </li>
                      <li>
                        <b>Health Checks</b>: Perform automated and manual
                        checks to confirm that the system is operational.
                      </li>
                    </ul>
                    <p>
                      <mark>CI/CD Pipelines</mark>: Implement Continuous
                      Integration (CI) and Continuous Deployment (CD) practices
                      to automate and streamline the deployment process. This
                      includes:
                      <ul>
                        <li>
                          <b>Automated Testing</b>: Running automated tests to
                          ensure code quality.
                        </li>
                        <li>
                          <b>Deployment Automation</b>: Using tools like
                          Jenkins, GitLab CI/CD, or CircleCI to automate the
                          deployment process.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <mark>Feedback Loops</mark>: Establish feedback loops to
                      continuously improve the deployment process based on user
                      feedback and performance metrics.
                    </p>
                  </p>
                </AccordionBody>
              </AccordionItem>

              <AccordionItem className="accordion-item">
                <AccordionHeader targetId="5">05. Maintenance</AccordionHeader>
                <AccordionBody accordionId="5" className="acc_body">
                  <p className="m-0">
                    <p>
                      <mark>Monitoring</mark>:
                      <ul>
                        <li>
                          Definition: Continuously tracking the software’s
                          performance and health to detect issues early.
                        </li>
                        <li>
                          Tools: Monitoring tools like New Relic, Datadog, or
                          Prometheus for system performance, logs, and user
                          activity.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <mark>Bug Tracking and Resolution</mark>: Definition:
                      Managing and resolving issues reported by users or
                      discovered during monitoring. Tools: Issue tracking
                      systems like Jira, Bugzilla, or GitHub Issues. Activities:
                      Logging defects, prioritizing fixes, and releasing patches
                      or updates.
                    </p>
                    <p>
                      <mark>Performance Tuning</mark>:
                      <ul>
                        <li>
                          Definition: Optimizing the software to enhance its
                          performance and efficiency.
                        </li>
                        <li>
                          Tools: Analyzing performance metrics, optimizing
                          queries or algorithms, and improving resource usage.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <mark>Security Updates</mark>:
                      <ul>
                        <li>
                          Definition: Applying patches and updates to address
                          security vulnerabilities.
                        </li>
                        <li>
                          Tools: Regularly updating libraries and frameworks,
                          conducting security assessments, and responding to new
                          threats.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <mark>User Support</mark>:
                      <ul>
                        <li>
                          Definition: Providing assistance to users experiencing
                          issues or needing help with the software.
                        </li>
                        <li>
                          Tools: Offering helpdesk support, resolving user
                          queries, and providing training or documentation.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <mark>Documentation Updates</mark>:
                      <ul>
                        <li>
                          Definition: Keeping user manuals, system
                          documentation, and training materials up to date.
                        </li>
                        <li>
                          Tools: Updating documentation to reflect changes, new
                          features, or changes in system behavior.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <mark>Backup and Recovery</mark>:
                      <ul>
                        <li>
                          Definition: Ensuring that data is backed up regularly
                          and that recovery procedures are in place.
                        </li>
                        <li>
                          Tools: Performing regular backups, testing recovery
                          processes, and ensuring data integrity.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <mark>Compliance and Audits</mark>:
                      <ul>
                        <li>
                          Definition: Ensuring that the software remains
                          compliant with regulatory requirements and standards.
                        </li>
                        <li>
                          Tools: Conducting regular audits, updating policies,
                          and ensuring adherence to compliance requirements.
                        </li>
                      </ul>
                    </p>
                  </p>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
          <div className="col-lg-5">
            <Accordion
              open={open}
              toggle={toggle}
              className="accordion"
              id="service_process_faq"
            >
              <ul className="content_layer_group unordered_list_block text-center">
                <li>
                  <AccordionHeader targetId="1">
                    <span>Discovery Phase</span>
                  </AccordionHeader>
                </li>
                <li>
                  <AccordionHeader targetId="2">
                    <span>Design and Development</span>
                  </AccordionHeader>
                </li>
                <li>
                  <AccordionHeader targetId="3">
                    <span>Testing and QA</span>
                  </AccordionHeader>
                </li>

                <li>
                  <AccordionHeader targetId="4">
                    <span>Deployment</span>
                  </AccordionHeader>
                </li>
                <li>
                  <AccordionHeader targetId="5">
                    <span>Maintenance</span>
                  </AccordionHeader>
                </li>
              </ul>
            </Accordion>
          </div>
        </div>
        <TechnologySection />
        {/* <Testimonial/> */}
      </div>

      <div className="decoration_item shape_image_1">
        <img src={shape1} alt="Quadgen Shape" />
      </div>
      <div className="decoration_item shape_image_2">
        <img src={shape2} alt="Quadgen Shape" />
      </div>
      <div className="decoration_item shape_image_3">
        <img src={shape3} alt="Quadgen Shape" />
      </div>
      <div className="decoration_item shape_image_4">
        <img src={shape4} alt="Quadgen Shape" />
      </div>
    </section>
  );
};
export default FaqSection;
