import React, { Fragment, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link, useNavigate } from "react-router-dom";

const menus = [
  {
    id: 1,
    title: "Home",
    section: "",
  },
  {
    id: 3,
    title: "Services",
    section: "services_section",
  },
  {
    id: 4,
    title: "Technologies",
    section: "technologies_section",
  },
  {
    id: 5,
    title: "Contact",
    section: "contact_section",
  },
  {
    id: 6,
    title: "Get Started",
    section: "contact_section",
  },
];

const MobileMenu = () => {
  const navigate = useNavigate();
  const [openId, setOpenId] = useState(0);

  const handleHomeClick = () => {
    window.scrollTo(10, 0);
  };

  const handleClick = (sectionId) => {
    navigate(`/#${sectionId}`);
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <ul className="main_menu_list clearfix">
      {menus.map((item, mn) => {
        return (
          <ListItem className={item.id === openId ? "active" : null} key={mn}>
            {item.submenu ? (
              <Fragment>
                <p onClick={() => setOpenId(item.id === openId ? 0 : item.id)}>
                  {item.title}
                  <i
                    className={
                      item.id === openId ? "fa fa-angle-up" : "fa fa-angle-down"
                    }
                  ></i>
                </p>
                <Collapse in={item.id === openId} timeout="auto" unmountOnExit>
                  <List className="subMenu">
                    <Fragment>
                      {item.submenu.map((submenu, i) => {
                        return (
                          <ListItem key={i}>
                            <Link className="active" to={submenu.link}>
                              {submenu.title}
                            </Link>
                          </ListItem>
                        );
                      })}
                    </Fragment>
                  </List>
                </Collapse>
              </Fragment>
            ) : (
              <Link
                className="active"
                onClick={() =>
                  item.title === "Home"
                    ? handleHomeClick()
                    : handleClick(item.section)
                }
                to="/"
              >
                {item.title}
              </Link>
            )}
          </ListItem>
        );
      })}
    </ul>
  );
};

export default MobileMenu;
